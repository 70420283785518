<template>
  <div>
    <v-card-text>
      <h2 class="info--text">Validação da Declaração Emitida</h2>
      <br />
      <br />
      <div class="white info--text" v-if="fTokenInProcess">
        <v-icon class="white info--text">mdi-alert</v-icon>
        Validando Token, por favor aguarde...
      </div>
      <div v-if="!fTokenInProcess">
        <div class="white info--text" v-if="fValidToken">
          <v-icon class="white info--text">mdi-alert</v-icon
          >{{ fTextTokenValidado }}
        </div>
        <div class="white error--text" v-if="!fValidToken">
          <v-icon class="white error--text">mdi-alert</v-icon
          >{{ fTextTokenInvalido }}
        </div>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import apiDeclaracao from '@/services/api'
import { cClienteStr, cClienteStrCompleto } from '@/services/consts'

export default {
  name: 'AreaDoAssociadoValidaDeclaracao',

  /*components: {
    TitleForm
  },*/

  data() {
    return {
      fValidToken: false,
      fLink: '',
      fTokenInProcess: true,
      fClienteStr: cClienteStr,
      fClienteStrCompleto: cClienteStrCompleto,
      fMsgValidaDeclaracao: ''
    }
  },

  computed: {
    fTextTokenValidado: function() {
      return this.fMsgValidaDeclaracao
    },
    fTextTokenInvalido: function() {
      return (
        'Não foi possível validar a emissão desta declaração, entre em contato com o Atendimento da ' +
        this.fClienteStr +
        ' para maiores informações'
      )
    }
  },

  mounted() {
    this.fTokenInProcess = true
    apiDeclaracao
      .get(`/dec/validatokendeclaracao/${this.$route.params.token}`)
      .then(aRes => {
        this.fMsgValidaDeclaracao = aRes.data.MsgValidaDeclaracao
        this.fValidToken = true
      })
      .catch(() => {
        this.fValidToken = false
      })
      .then(() => {
        this.fTokenInProcess = false
      })
  }
}
</script>
