import axios from 'axios'
import {
  cBaseURL,
  cBaseURLExt,
  cBaseURLDeclaracaoAssociado
} from '@/services/consts'

const api = axios.create({
  baseURL: cBaseURL,

  headers: {
    'Content-Type': 'application/json'
  }
})

const apiExt = axios.create({
  baseURL: cBaseURLExt,

  headers: {
    'Content-Type': 'application/json'
  }
})

const apiDeclaracao = axios.create({
  baseURL: cBaseURLDeclaracaoAssociado,

  headers: {
    'Content-Type': 'application/json'
  }
})

const setToken = aToken => {
  api.defaults.headers.common.Authorization = aToken
}

export { api, apiExt, apiDeclaracao, setToken }
export default api
