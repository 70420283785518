import Vue from 'vue'
import Vuex from 'vuex'
import { api, apiExt } from '@/services/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { apiversion: '', listaProfissoes: [] },
  mutations: {
    SET_INFOAPIVERSION: (aState, aValue) => {
      aState.apiversion = aValue
    },
    SET_LISTAPROFISSOES: (aState, aValue) => {
      aState.listaProfissoes = [...aValue]
    }
  },
  actions: {
    checkAPIVersion(aContext) {
      console.log('get information "checkAPIVersion" ')
      return new Promise((resolve, reject) => {
        api
          .get('/status')
          .then(lResponse => {
            aContext.commit('SET_INFOAPIVERSION', lResponse.data.Version)
            console.log('lResponse.data: ' + lResponse.data)
            resolve({
              apiversion: lResponse.data.Version
            })
          })
          .catch(() => {
            aContext.commit('SET_INFOAPIVERSION', 'OffLine')
            reject({
              apiversion: 'OffLine'
            })
          })
      })
    },
    getListaProfissoes(aContext) {
      // http://api.isapps.com.br/asprofili/api/v1/ext/profissao
      //return true
      return new Promise((resolve, reject) => {
        apiExt
          .get('/profissao')
          .then(lResponse => {
            aContext.commit('SET_LISTAPROFISSOES', lResponse.data)
            console.log('lResponse.data: ' + lResponse.data)
            resolve(true)
            //resolve({
            //  listaProfissoes: lResponse.data.Version
            //})
          })
          .catch(lError => {
            //aContext.commit('SET_CATEGORIAPROFISSIONALLIST', [])
            aContext.commit('SET_LISTAPROFISSOES', [])
            reject(lError)
          })
        //.catch(() => {
        //  aContext.commit('SET_LISTAPROFISSOES', [])
        //  reject({
        //    listaProfissoes: 'OffLine'
        //  })
        //})
      })
    }
  },
  getters: {
    ApiVersion: aState => {
      return aState.apiversion
    },
    ApiVersionOffLine: aState => {
      return aState.apiversion == 'OffLine'
    },
    ListaProfissoes: aState => {
      return aState.listaProfissoes
    }
  },
  modules: {}
})
