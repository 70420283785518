const _CurrentDateTime = () => {
  var lDatetime = new Date()
  var lResult =
    lDatetime.getFullYear() +
    '-' +
    (lDatetime.getMonth() + 1) +
    '-' +
    lDatetime.getDate() +
    'T' +
    lDatetime.getHours() +
    ':' +
    lDatetime.getMinutes() +
    ':' +
    lDatetime.getSeconds()
  return lResult
}

export { _CurrentDateTime }
