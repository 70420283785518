<template>
  <div>
    <v-form>
      <!--MessageAlert
        :fShowMessageMaintenance="fShowMessageMaintenance"
      ></!--MessageAlert-->
      <v-card-text>
        <!-- div class="white info--text">
          Login
        </div -->
        <h2 class="info--text">Código de Validação da Declaração</h2>
        <br />
        <h4 class="red--text">
          <v-icon class="red--text">mdi-alert</v-icon>
          ATENÇÃO: Esta forma de verificação, só terá funcionalidade em
          declarações emitidas a partir de 21/03/2022. As declarações
          anteriores, deverão ser validadas pelo QR Code.
          <!--ATENÇÃO: Esta funcionalidade esta disponível para validação dos códigos constantes nas declarações emitidas após 21/03/2022.<br />
          Declarações anteriores a 21/03/2022, deverão ser validadas pelo QR Code.-->
        </h4>
        <v-row>
          <v-col cols="9" sm="2" md="4">
            <v-text-field
              _prepend-icon="mdi-alpha-g-circle-outline"
              class="info--text"
              label="Informe o código de validação da declaração"
              v-model="fFormData.CodValidDeclaracao"
              type="text"
              maxlength="30"
            />
          </v-col>
        </v-row>

        <v-card-text v-if="fShowMsgAlert">
          <div :class="fClassMsgAlert">
            <v-icon :class="fClassMsgAlert">mdi-alert</v-icon> {{ fMsgAlert }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="white"
            class="text-none info--text"
            @click="DoValidaCodigo"
            :disabled="fDisableBtnEnviar"
          >
            <v-icon class="info--text">mdi-login-variant</v-icon> Enviar
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-form>
  </div>
</template>

<script>
import apiDeclaracao from '@/services/api'
import { cClienteStr } from '@/services/consts'

export default {
  name: 'AreaDoAssociadoValidaCodigoDeclaracao',

  data() {
    return {
      fShowMsgAlert: false,
      fClassMsgAlert: '',
      fMsgAlert: '',
      fClienteStr: cClienteStr,

      fFormData: { CodValidDeclaracao: '' },
      //fValid: false,
      fDisableBtnEnviar: false
      /*rules: {
        email: value => {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (re.test(value)) {
            return true
          } else {
            return 'E-mail inválido'
          }
        }
      }*/
    }
  },

  methods: {
    /*Validate() {
      this.$refs.form.validate()
    },*/

    ShowMsgAlert(aText, aClass) {
      this.fMsgAlert = aText
      this.fClassMsgAlert = aClass
      this.fShowMsgAlert = true
    },

    DoValidaCodigo() {
      let lCod = this.fFormData.CodValidDeclaracao

      if (lCod.substring(0, 2) == 'G.') {
        lCod = lCod.substring(2, 40)
      }

      this.fDisableBtnEnviar = true

      apiDeclaracao
        //.get(`/dec/validacoddeclaracao/${this.fFormData.CodValidDeclaracao}`)
        .get(`/dec/validacoddeclaracao/${lCod}`)
        .then(aRes => {
          this.ShowMsgAlert(aRes.data.MsgValidaDeclaracao, 'white info--text')
        })
        .catch(() => {
          this.ShowMsgAlert(
            'Não foi possível validar o código informado, entre em contato com o Atendimento da ' +
              this.fClienteStr +
              ' para maiores informações',
            'white error--text'
          )
          this.fDisableBtnEnviar = false
        })
        .then(() => {
          this.fDisableBtnEnviar = false
        })
      /*
      api
        //.get(`/emaillogin/${this.fFormData.Email}`)
        .post('/emaillogin', this.fFormData)
        .then(() => {
          this.ShowMsgAlert(
            'Verifique sua caixa de email, um email com o link para login acaba de ser enviado',
            'white info--text'
          )
        })
        .catch(lError => {
          this.ShowMsgAlert(lError.response.data.MsgErro, 'white error--text')
          this.fDisableBtnEnviar = false
        })
        */
    }
  }
  /*
  computed: {
    fShowMessageMaintenance: function() {
      return cShowMessageMaintenance
    }
  }
  */
}
</script>
